module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SaSuCo Oy","short_name":"SaSuCo Oy","start_url":"https://sasuco.fi","scope":"https://sasuco.fi","icon":"src/images/favicon.png","legacy":true,"display":"browser"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
